import { PropsSVG } from "./types";

function FilterIcon({ height = 20, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M11.3469 8.3296L11.6643 8.71587L11.3469 8.3296ZM5.41908 8.33716L5.75159 7.96374L5.41908 8.33716ZM9.41165 13.3947L9.12315 12.9864L9.41165 13.3947ZM8.11747 14.309L8.40597 14.7174L8.11747 14.309ZM3.25069 6.40636L2.91819 6.77978L3.25069 6.40636ZM13.6937 6.40061L13.3763 6.01434L13.6937 6.40061ZM11.1667 5.66667C11.4428 5.66667 11.6667 5.44281 11.6667 5.16667C11.6667 4.89052 11.4428 4.66667 11.1667 4.66667V5.66667ZM5.83333 4.66667C5.55719 4.66667 5.33333 4.89052 5.33333 5.16667C5.33333 5.44281 5.55719 5.66667 5.83333 5.66667L5.83333 4.66667ZM4.81497 3H12.2228V2H4.81497V3ZM9.12315 12.9864L7.82897 13.9006L8.40597 14.7174L9.70015 13.8031L9.12315 12.9864ZM7 13.5089V10.724H6V13.5089H7ZM5.75159 7.96374L3.58319 6.03294L2.91819 6.77978L5.08658 8.71058L5.75159 7.96374ZM13.3763 6.01434L11.0294 7.94333L11.6643 8.71587L14.0112 6.78688L13.3763 6.01434ZM9.66667 10.8014V11.9545H10.6667V10.8014H9.66667ZM11.0294 7.94333C10.1676 8.65162 9.66667 9.6964 9.66667 10.8014H10.6667C10.6667 10.0015 11.0291 9.23801 11.6643 8.71587L11.0294 7.94333ZM7 10.724C7 9.67112 6.54493 8.67016 5.75159 7.96374L5.08658 8.71058C5.67076 9.23074 6 9.96167 6 10.724H7ZM9.70015 13.8031C10.3041 13.3765 10.6667 12.6904 10.6667 11.9545H9.66667C9.66667 12.3582 9.46794 12.7428 9.12315 12.9864L9.70015 13.8031ZM7.82897 13.9006C7.46497 14.1578 7 13.8853 7 13.5089H6C6 14.7497 7.42365 15.4114 8.40597 14.7174L7.82897 13.9006ZM2 4.74871C2 5.52432 2.33526 6.26072 2.91819 6.77978L3.58319 6.03294C3.20944 5.70014 3 5.23376 3 4.74871H2ZM14 4.712C14 5.21012 13.7744 5.68713 13.3763 6.01434L14.0112 6.78688C14.6358 6.27352 15 5.51521 15 4.712H14ZM12.2228 3C13.2181 3 14 3.78008 14 4.712H15C15 3.20062 13.7428 2 12.2228 2V3ZM4.81497 2C3.27409 2 2 3.21705 2 4.74871H3C3 3.79651 3.7988 3 4.81497 3V2ZM11.1667 4.66667L5.83333 4.66667L5.83333 5.66667L11.1667 5.66667V4.66667Z"
        fill="#172C37"
      />
    </svg>
  );
}

export default FilterIcon;
