import { PropsSVG } from "./types";

function GreenCirclePhoneIcon({ height = 28, width = 28, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="0.5" width="24" height="24" rx="12" fill="#20B038" />
      <path
        d="M5.2209 18.9802L6.24983 15.2438C5.6137 14.1471 5.27999 12.9051 5.28347 11.6354C5.28347 7.65685 8.53712 4.42212 12.5312 4.42212C14.4709 4.42212 16.2924 5.17285 17.6585 6.53594C19.0281 7.89903 19.7824 9.71186 19.7789 11.6389C19.7789 15.6174 16.5253 18.8521 12.5277 18.8521H12.5242C11.3111 18.8521 10.1188 18.5477 9.05854 17.9734L5.2209 18.9802ZM9.24278 16.6691L9.46177 16.8006C10.3864 17.3472 11.4466 17.6344 12.5277 17.6378H12.5312C15.8509 17.6378 18.5553 14.9497 18.5553 11.6423C18.5553 10.0405 17.9296 8.53559 16.7929 7.40084C15.6562 6.26609 14.1406 5.64336 12.5312 5.64336C9.21149 5.6399 6.50707 8.32802 6.50707 11.6354C6.50707 12.7667 6.82339 13.8703 7.42824 14.8252L7.57076 15.0535L6.96244 17.2642L9.24278 16.6691Z"
        fill="white"
      />
      <path
        d="M5.47467 18.7275L6.46884 15.1191C5.85357 14.0639 5.53029 12.8635 5.53029 11.6388C5.53376 7.79859 8.67271 4.67456 12.5312 4.67456C14.4048 4.67456 16.1603 5.40108 17.4812 6.71573C18.8021 8.03038 19.5287 9.78094 19.5287 11.6422C19.5287 15.4824 16.3862 18.6064 12.5312 18.6064H12.5277C11.3563 18.6064 10.2057 18.3123 9.1837 17.7588L5.47467 18.7275Z"
        fill="#20B038"
      />
      <path
        d="M5.2209 18.9802L6.24983 15.2438C5.6137 14.1471 5.27999 12.9051 5.28347 11.6354C5.28347 7.65685 8.53712 4.42212 12.5312 4.42212C14.4709 4.42212 16.2924 5.17285 17.6585 6.53594C19.0281 7.89903 19.7824 9.71186 19.7789 11.6389C19.7789 15.6174 16.5253 18.8521 12.5277 18.8521H12.5242C11.3111 18.8521 10.1188 18.5477 9.05854 17.9734L5.2209 18.9802ZM9.24278 16.6691L9.46177 16.8006C10.3864 17.3472 11.4466 17.6344 12.5277 17.6378H12.5312C15.8509 17.6378 18.5553 14.9497 18.5553 11.6423C18.5553 10.0405 17.9296 8.53559 16.7929 7.40084C15.6562 6.26609 14.1406 5.64336 12.5312 5.64336C9.21149 5.6399 6.50707 8.32802 6.50707 11.6354C6.50707 12.7667 6.82339 13.8703 7.42824 14.8252L7.57076 15.0535L6.96244 17.2642L9.24278 16.6691Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.7201 8.61854C10.5845 8.31755 10.442 8.31063 10.3134 8.30717C10.2091 8.30371 10.0874 8.30371 9.96576 8.30371C9.84409 8.30371 9.64943 8.34869 9.48258 8.52859C9.31572 8.70849 8.84992 9.1444 8.84992 10.0335C8.84992 10.9192 9.49996 11.7772 9.59034 11.8982C9.68072 12.0193 10.8452 13.8979 12.6841 14.621C14.2136 15.2229 14.5264 15.1018 14.8567 15.0707C15.1869 15.0396 15.9273 14.6348 16.0803 14.2127C16.2297 13.7907 16.2297 13.4309 16.1846 13.3547C16.1394 13.2786 16.0177 13.2337 15.8369 13.1437C15.6562 13.0538 14.7663 12.6178 14.5994 12.5556C14.4326 12.4968 14.3109 12.4656 14.1927 12.6455C14.0711 12.8254 13.7235 13.2302 13.6192 13.3513C13.5149 13.4724 13.4071 13.4862 13.2264 13.3963C13.0456 13.3063 12.4616 13.116 11.7699 12.5002C11.2311 12.0228 10.8661 11.4312 10.7618 11.2513C10.6575 11.0714 10.7514 10.9745 10.8417 10.8846C10.9217 10.805 11.0225 10.6735 11.1129 10.5698C11.2033 10.466 11.2345 10.3899 11.2936 10.2688C11.3527 10.1477 11.3249 10.0439 11.2797 9.95394C11.2345 9.86745 10.88 8.97488 10.7201 8.61854Z"
        fill="white"
      />
    </svg>
  );
}

export default GreenCirclePhoneIcon;
