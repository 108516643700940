import { PropsSVG } from "./types";

function TiktokLightIcon({ height = 18, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1308_4995)">
        <path
          d="M17.9069 0.569824H13.7636V16.9176C13.7636 18.8655 12.17 20.4655 10.1869 20.4655C8.20373 20.4655 6.61014 18.8655 6.61014 16.9176C6.61014 15.0046 8.16832 13.4394 10.0807 13.3698V9.26549C5.86646 9.33502 2.4668 12.709 2.4668 16.9176C2.4668 21.1612 5.93728 24.5698 10.2223 24.5698C14.5072 24.5698 17.9777 21.1264 17.9777 16.9176V8.53502C19.5359 9.64809 21.4482 10.309 23.4668 10.3438V6.23939C20.3505 6.13504 17.9069 3.63069 17.9069 0.569824Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_1308_4995">
          <rect
            x="0.666748"
            y="0.569824"
            width={24}
            height={24}
            rx={6}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default TiktokLightIcon;
