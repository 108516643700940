import { PropsSVG } from "./types";

function BackIcon({ width = 18, height = 18, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19 12.5H5"
        stroke="black"
        strokeOpacity="1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5.5L5 12.5L12 19.5"
        stroke="black"
        strokeOpacity="1"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default BackIcon;
