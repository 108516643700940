import { PropsSVG } from "./types";

function CheckedIcon({ width = 32, height = 32, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect y="0.5" width="16" height="16" rx="8" fill="#34B315" />
      <path
        d="M11.3327 6L6.74935 10.5833L4.66602 8.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default CheckedIcon;
