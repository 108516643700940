import { PropsSVG } from "./types";

function OpenMenuIcon({ height = 29, width = 28, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 28 29"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.4997 21.5L22.1663 21.5M15.1663 7.5L22.1663 7.5M5.83301 14.5H22.1663"
        stroke="#2D264B"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}

export default OpenMenuIcon;
