import { PropsSVG } from "./types";

function ChevronRightIcon({ height = 20, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.5 15.0698L12.5 10.0698L7.5 5.06982"
        stroke="#172C37"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ChevronRightIcon;
