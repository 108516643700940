import { PropsSVG } from "./types";

function FacebookLightIcon({ height = 18, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={25}
      height={25}
      viewBox="0 0 25 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1308_4993)">
        <rect
          x="0.666748"
          y="0.570312"
          width={24}
          height={24}
          rx={12}
          fill="white"
        />
        <path
          d="M17.3378 16.0391L17.8699 12.5703H14.5417V10.3203C14.5417 9.37109 15.0058 8.44531 16.4964 8.44531H18.0105V5.49219C18.0105 5.49219 16.6371 5.25781 15.3246 5.25781C12.5824 5.25781 10.7917 6.91953 10.7917 9.92656V12.5703H7.74487V16.0391H10.7917V24.425C11.4035 24.5211 12.0292 24.5703 12.6667 24.5703C13.3042 24.5703 13.93 24.5211 14.5417 24.425V16.0391H17.3378Z"
          fill="#172C37"
        />
      </g>
      <defs>
        <clipPath id="clip0_1308_4993">
          <rect
            x="0.666748"
            y="0.570312"
            width={24}
            height={24}
            rx={12}
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FacebookLightIcon;
