import { PropsSVG } from "./types";

const LightBulbIcon = ({ height = 20, width = 20, ...props }: PropsSVG) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={height}
      height={width}
      viewBox="0 0 32 32"
      {...props}
    >
      <path
        fill="currentColor"
        d="M15.691 1c-5.315 0-9.628 4.276-9.69 9.579v.005a3 3 0 0 0 0 .209c.003.13.012.309.033.528a9.67 9.67 0 0 0 3.598 6.928c1.836 2.072 2.29 3.928 2.342 4.552v.006q.009.098.03.193H12v1.79l7.5-1.498v-.05q.087-.205.107-.435V22.8c.057-.689.36-2.524 2.153-4.541a9.66 9.66 0 0 0 3.599-6.969a8 8 0 0 0 .032-.571v-.113C25.324 5.284 21.012 1 15.691 1m-7.69 9.61A7.69 7.69 0 0 1 15.691 3c4.217 0 7.644 3.393 7.7 7.627v.093c0 .053-.006.174-.026.417l-.002.012v.013a7.67 7.67 0 0 1-2.911 5.583l-.067.052l-.057.063c-1.888 2.095-2.473 4.097-2.653 5.27h-.632c.045-1.535.249-4.395 1.118-5.35c.86-.68 1.43-1.71 1.5-2.88c.01-.11.01-.18.01-.23v-.04a3.97 3.97 0 0 0-3.97-3.93a3.96 3.96 0 0 0-2.45 7.07c.862.963 1.074 3.825 1.125 5.36h-.48c-.224-1.207-.966-3.201-2.83-5.278l-.058-.064l-.068-.053a7.67 7.67 0 0 1-2.91-5.558l-.002-.018l-.002-.019A5 5 0 0 1 8 10.656v-.029zm7.7.09c1.62 0 2.94 1.31 2.96 2.93v.08c0 .03 0 .07-.01.13c-.05.84-.46 1.63-1.12 2.15l-.07.05l-.06.06c-1.1 1.22-1.33 4.32-1.37 6.02h-.65c-.05-1.7-.29-4.8-1.39-6.02l-.06-.06l-.07-.05a2.96 2.96 0 0 1-1.12-2.17a.3.3 0 0 0-.006-.06l-.004-.03v-.09c.03-1.62 1.36-2.94 2.97-2.94M19.5 24.312L12 25.81v.969l7.5-1.58zM12 28v-.2l7.5-1.579V28a1 1 0 0 1-1 1h-1.116a1.96 1.96 0 0 1-1.692 1A1.96 1.96 0 0 1 14 29h-1a1 1 0 0 1-1-1"
      />
    </svg>
  );
};

export default LightBulbIcon;
