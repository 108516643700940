import { PropsSVG } from "./types";

import { cn } from "~/utilities/cn";

function ChevronDownIcon({ height = 20, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={height}
      height={width}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M5 7.5L10 12.5L15 7.5"
        stroke={cn(props.className ? "currentColor" : "#172C37")}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ChevronDownIcon;
