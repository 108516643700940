import { PropsSVG } from "./types";

function GreenPhoneIcon({ height = 28, width = 28, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.99 2.75C13.8509 2.75 14.2319 2.75076 14.5461 2.77889C18.0394 3.09165 20.8079 5.86017 21.1207 9.35348C21.1488 9.66768 21.1496 10.0487 21.1496 10.9095V11.8995C21.1496 12.3137 21.4854 12.6495 21.8996 12.6495C22.3138 12.6495 22.6496 12.3137 22.6496 11.8995V10.8591C22.6496 10.0622 22.6496 9.60934 22.6147 9.21972C22.2372 5.00365 18.8959 1.66234 14.6799 1.28486C14.2902 1.24998 13.8374 1.24999 13.0404 1.25H12.0001C11.5859 1.25 11.2501 1.58579 11.2501 2C11.2501 2.41422 11.5859 2.75 12.0001 2.75L12.99 2.75Z"
        fill="#20B038"
      />
      <path
        d="M7.1118 4.0253C6.05971 3.05857 4.44256 3.05857 3.39047 4.0253C3.34739 4.06489 3.30139 4.11089 3.24167 4.17063L2.34416 5.06814C1.44105 5.97125 1.06193 7.27317 1.33899 8.51995C2.91096 15.5938 8.4355 21.1183 15.5094 22.6903C16.7561 22.9674 18.0581 22.5883 18.9612 21.6851L19.8586 20.7877C19.9184 20.728 19.9644 20.6819 20.004 20.6388C20.9707 19.5867 20.9707 17.9696 20.004 16.9175C19.9644 16.8744 19.9184 16.8284 19.8586 16.7686L18.3893 15.2994C17.3692 14.2792 15.8287 13.9875 14.5062 14.564C13.7491 14.894 12.8672 14.727 12.2832 14.1429L9.88636 11.7461C9.30236 11.1621 9.13533 10.2802 9.46535 9.52308C10.0418 8.20062 9.75006 6.66009 8.72996 5.63999L7.26063 4.17065C7.20089 4.1109 7.15489 4.06489 7.1118 4.0253Z"
        fill="#20B038"
      />
      <path
        d="M12.7071 4.78554C12.2929 4.78554 11.9571 5.12132 11.9571 5.53554C11.9571 5.94975 12.2929 6.28554 12.7071 6.28554H13.2728C13.36 6.28553 13.3957 6.28555 13.4248 6.28597C15.7244 6.31891 17.5806 8.1751 17.6135 10.4747C17.6139 10.5037 17.6139 10.5395 17.6139 10.6267V11.1924C17.6139 11.6066 17.9497 11.9424 18.3639 11.9424C18.7781 11.9424 19.1139 11.6066 19.1139 11.1924V10.6194C19.1139 10.5417 19.1139 10.4944 19.1133 10.4532C19.0688 7.342 16.5575 4.83068 13.4463 4.78613C13.405 4.78553 13.3577 4.78553 13.28 4.78554H12.7071Z"
        fill="#20B038"
      />
    </svg>
  );
}

export default GreenPhoneIcon;
