import { PropsSVG } from "./types";

function StartVideoIcon({ height = 92, width = 91, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 91 92"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g opacity="0.5" filter="url(#filter0_d_4415_16747)">
        <circle cx="38" cy="44" r="32" fill="white" />
      </g>
      <path
        d="M31.2024 29.1518C29.2036 27.8959 26.6001 29.3275 26.6001 31.6826V55.1797C26.6001 57.5347 29.2036 58.9664 31.2024 57.7105L49.8993 45.9619C51.767 44.7883 51.767 42.074 49.8993 40.9004L31.2024 29.1518Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_4415_16747"
          x="0.352942"
          y="0.705882"
          width="90.3529"
          height="90.3529"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx="7.52941" dy="1.88235" />
          <feGaussianBlur stdDeviation="6.58824" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4415_16747"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4415_16747"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}

export default StartVideoIcon;
