import { PropsSVG } from "./types";

function ResetIcon({ ...props }: PropsSVG) {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.75 3.5V8H5.25"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.25 15.5V11H12.75"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.3675 7.24993C14.9871 6.17502 14.3407 5.21399 13.4884 4.4565C12.6361 3.69901 11.6059 3.16976 10.4938 2.91813C9.38167 2.6665 8.22393 2.70069 7.12861 3.01751C6.03328 3.33434 5.03606 3.92347 4.23 4.72993L0.75 7.99993M17.25 10.9999L13.77 14.2699C12.9639 15.0764 11.9667 15.6655 10.8714 15.9824C9.77607 16.2992 8.61833 16.3334 7.50621 16.0817C6.3941 15.8301 5.36385 15.3009 4.5116 14.5434C3.65935 13.7859 3.01288 12.8248 2.6325 11.7499"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ResetIcon;
