import { PropsSVG } from "./types";

function EmailIcon({ height = 18, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M1.94419 5.84123C1.6665 6.87455 1.6665 8.30007 1.6665 10.5C1.6665 13.6248 1.6665 15.1872 2.46227 16.2824C2.71926 16.6362 3.03034 16.9472 3.38407 17.2042C4.47934 18 6.04173 18 9.1665 18H10.8332C13.9579 18 15.5203 18 16.6156 17.2042C16.9693 16.9472 17.2804 16.6362 17.5374 16.2824C18.3332 15.1872 18.3332 13.6248 18.3332 10.5C18.3332 8.29274 18.3332 6.86505 18.0527 5.83091L16.3004 7.58322C14.9402 8.94345 13.8742 10.0094 12.9307 10.7293C11.9649 11.4662 11.0543 11.8991 9.99972 11.8991C8.94518 11.8991 8.03457 11.4662 7.06879 10.7293C6.12524 10.0094 5.05926 8.94344 3.69904 7.5832L2.06972 5.95388L1.94419 5.84123Z"
        fill="#172C37"
      />
      <path
        d="M2.49984 4.66667L2.59054 4.74172L2.92973 5.04613L4.54911 6.66551C5.95049 8.06689 6.95801 9.07253 7.82702 9.73556C8.6816 10.3876 9.32838 10.6491 9.99972 10.6491C10.6711 10.6491 11.3178 10.3876 12.1724 9.73556C13.0414 9.07253 14.049 8.06689 15.4503 6.66551L17.3736 4.74225L17.4783 4.63816C17.2213 4.28443 16.9693 4.05276 16.6156 3.79576C15.5203 3 13.9579 3 10.8332 3H9.1665C6.04173 3 4.47934 3 3.38407 3.79576C3.03034 4.05276 2.75684 4.31294 2.49984 4.66667Z"
        fill="#172C37"
      />
    </svg>
  );
}

export default EmailIcon;
