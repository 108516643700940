import { PropsSVG } from "./types";

function ImageIcon({ height = 20, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1668 5.83337L13.3335 10L19.1668 14.1667V5.83337Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.6668 4.16663H2.50016C1.57969 4.16663 0.833496 4.91282 0.833496 5.83329V14.1666C0.833496 15.0871 1.57969 15.8333 2.50016 15.8333H11.6668C12.5873 15.8333 13.3335 15.0871 13.3335 14.1666V5.83329C13.3335 4.91282 12.5873 4.16663 11.6668 4.16663Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ImageIcon;
