import { PropsSVG } from "./types";

function Phone({ height = 20, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      {...props}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.25171 12.3285C10.1178 14.1324 11.2986 15.8232 12.7938 17.3185C14.2891 18.8138 15.9799 19.9945 17.7839 20.8606C17.939 20.9351 18.0166 20.9724 18.1148 21.001C18.4637 21.1027 18.8921 21.0296 19.1876 20.8181C19.2707 20.7585 19.3418 20.6874 19.4841 20.5452C19.9192 20.1101 20.1367 19.8926 20.3554 19.7503C21.1804 19.214 22.2438 19.214 23.0688 19.7503C23.2875 19.8926 23.5051 20.1101 23.9401 20.5452L24.1826 20.7877C24.844 21.449 25.1746 21.7797 25.3543 22.1348C25.7115 22.8411 25.7115 23.6752 25.3543 24.3815C25.1746 24.7367 24.844 25.0673 24.1826 25.7287L23.9865 25.9249C23.3274 26.5839 22.9978 26.9135 22.5498 27.1652C22.0526 27.4445 21.2805 27.6453 20.7102 27.6436C20.1963 27.642 19.8451 27.5423 19.1427 27.343C15.3678 26.2716 11.8058 24.25 8.83405 21.2783C5.86234 18.3066 3.84077 14.7445 2.76934 10.9696C2.56997 10.2672 2.47029 9.91599 2.46876 9.4021C2.46706 8.83187 2.66787 8.0597 2.94715 7.56254C3.19883 7.1145 3.52838 6.78495 4.18746 6.12587L4.38363 5.9297C5.04498 5.26835 5.37566 4.93767 5.7308 4.75804C6.4371 4.4008 7.27121 4.4008 7.9775 4.75804C8.33264 4.93767 8.66332 5.26835 9.32467 5.9297L9.56717 6.1722C10.0022 6.60727 10.2198 6.8248 10.362 7.04355C10.8983 7.86848 10.8983 8.93196 10.362 9.75689C10.2198 9.97563 10.0022 10.1932 9.56717 10.6282C9.42492 10.7705 9.35379 10.8416 9.29426 10.9248C9.08269 11.2202 9.00963 11.6486 9.11134 11.9975C9.13995 12.0957 9.1772 12.1733 9.25171 12.3285Z"
        fill="white"
      />
      <path
        d="M17.5508 7.53332C18.7663 7.77047 19.8834 8.36493 20.759 9.24062C21.6347 10.1163 22.2292 11.2334 22.4663 12.4489M17.5508 2.55554C20.0761 2.83608 22.431 3.96696 24.2288 5.76249C26.0266 7.55801 27.1604 9.91147 27.4441 12.4364"
        stroke="white"
        strokeWidth="1.86667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default Phone;
