import { PropsSVG } from "./types";

function SuccessIcon({ height = 18, width = 18, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="18" height="18" rx="9" fill="white" />
      <path
        d="M13 6L7.5 11.5L5 9"
        stroke="#099628"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default SuccessIcon;
