import { PropsSVG } from "./types";

function TiktokDarkIcon({ height = 24, width = 24, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={24}
      height={24}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.24 0H13.0966V16.3478C13.0966 18.2957 11.503 19.8957 9.51988 19.8957C7.53673 19.8957 5.94315 18.2957 5.94315 16.3478C5.94315 14.4348 7.50133 12.8695 9.41366 12.8V8.69567C5.19947 8.7652 1.7998 12.1391 1.7998 16.3478C1.7998 20.5913 5.27029 24 9.55531 24C13.8403 24 17.3107 20.5565 17.3107 16.3478V7.9652C18.8689 9.07827 20.7812 9.73913 22.7998 9.77393V5.66957C19.6835 5.56522 17.24 3.06087 17.24 0Z"
        fill="#172C37"
      />
    </svg>
  );
}

export default TiktokDarkIcon;
