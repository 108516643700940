import { PropsSVG } from "./types";

function BellIcon({ ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 36 48"
      fill="none"
      className="text-highlightText"
    >
      <path
        d="M35 37.0004H0.999243C0.446998 37.0004 -0.000976562 36.5525 -0.000976562 36.0002C-0.000976562 35.448 0.446998 35 0.999243 35H35C35.5522 35 36.0002 35.448 36.0002 36.0002C36.0002 36.5525 35.5522 37.0004 35 37.0004Z"
        fill="currentColor"
      />
      <path
        d="M17.9992 48C15.2418 48 12.999 45.7572 12.999 42.9998V40.9994C12.999 40.4471 13.447 39.9991 13.9992 39.9991H21.9991C22.5513 39.9991 22.9993 40.4471 22.9993 40.9994V42.9998C22.9993 45.7572 20.7565 48 17.9992 48ZM14.9995 41.9996V42.9998C14.9995 44.6537 16.3453 45.9995 17.9992 45.9995C19.653 45.9995 20.9989 44.6537 20.9989 42.9998V41.9996H14.9985H14.9995Z"
        fill="currentColor"
      />
      <path
        d="M35 42.0006H0.999243C0.446998 42.0006 -0.000976562 41.5526 -0.000976562 41.0004V36.0002C-0.000976562 35.448 0.446998 35 0.999243 35C2.65308 35 3.99894 33.6542 3.99894 32.0003V18.0001C3.99894 14.8286 5.08895 11.7217 7.06912 9.25205C8.85137 7.02858 11.2892 5.39019 13.9992 4.58113V3.99992C13.9992 1.79383 15.794 0 17.9991 0C20.2043 0 21.9991 1.7948 21.9991 3.99992V4.58113C24.7091 5.39019 27.1479 7.02858 28.9292 9.25205C30.9084 11.7217 31.9994 14.8286 31.9994 18.0001V32.0003C31.9994 33.6542 33.3452 35 34.999 35C35.5513 35 35.9993 35.448 35.9993 36.0002V41.0004C35.9993 41.5526 35.5513 42.0006 34.999 42.0006H35ZM1.99946 40.0002H34.0008V36.9001C31.7213 36.4357 30.0008 34.415 30.0008 32.0003V18.0001C30.0008 12.4082 26.2056 7.60497 20.771 6.32186C20.3201 6.21566 20.0005 5.8121 20.0005 5.34867V3.99992C20.0005 2.89736 19.1036 1.99948 18.0001 1.99948C16.8966 1.99948 15.9997 2.89639 15.9997 3.99992V5.34867C15.9997 5.8121 15.6811 6.21566 15.2292 6.32186C9.79463 7.60593 5.99939 12.4082 5.99939 18.0001V32.0003C5.99939 34.415 4.27892 36.4357 1.99946 36.9001V40.0002Z"
        fill="currentColor"
      />
      <path
        d="M31.0002 27.0002H4.99924C4.447 27.0002 3.99902 26.5522 3.99902 26C3.99902 25.4477 4.447 24.9998 4.99924 24.9998H31.0002C31.5524 24.9998 32.0004 25.4477 32.0004 26C32.0004 26.5522 31.5524 27.0002 31.0002 27.0002Z"
        fill="currentColor"
      />
      <path
        d="M31.0002 31.0001H4.99924C4.447 31.0001 3.99902 30.5521 3.99902 29.9999C3.99902 29.4476 4.447 28.9996 4.99924 28.9996H31.0002C31.5524 28.9996 32.0004 29.4476 32.0004 29.9999C32.0004 30.5521 31.5524 31.0001 31.0002 31.0001Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default BellIcon;
