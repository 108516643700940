import { PropsSVG } from "./types";

function LocationIcon({ ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 40 48"
      fill="none"
      className="text-highlightText"
    >
      <path
        d="M20.0016 47.999H19.9968C19.7883 47.999 19.5904 47.9343 19.4282 47.8214C18.7977 47.4439 14.2419 44.649 9.66095 40.0468C6.80909 37.1805 4.54806 34.2089 2.94063 31.2122C0.906476 27.42 -0.0830876 23.5786 -6.10207e-05 19.7961C0.116755 14.4968 2.24166 9.52682 5.98365 5.80414C9.74398 2.06311 14.7198 0.00193085 19.9958 0H19.9997C19.9997 0 20.0026 0 20.0036 0C25.2796 0.000965426 30.2554 2.06215 34.0158 5.80414C37.7577 9.52778 39.8826 14.4968 39.9995 19.7961C40.0825 23.5796 39.0929 27.42 37.0588 31.2122C35.4513 34.2079 33.1903 37.1805 30.3384 40.0468C25.7556 44.6519 21.1978 47.4468 20.5703 47.8224C20.4071 47.9353 20.2111 48 20.0026 48L20.0016 47.999ZM19.9997 1.9994C10.2904 2.00036 2.21656 10.0028 1.99934 19.8395C1.82749 27.6459 6.82453 34.3518 11.0463 38.6035C14.8086 42.3909 18.6462 44.9637 19.9997 45.822C21.3523 44.9637 25.1908 42.3909 28.9531 38.6035C33.1749 34.3518 38.1719 27.6459 38.0001 19.8395C37.7838 10.0028 29.709 2.00036 19.9997 1.9994Z"
        fill="currentColor"
      />
      <path
        d="M19.9987 33.9984C12.2791 33.9984 5.99902 27.7183 5.99902 19.9988C5.99902 12.2792 12.2791 5.99915 19.9987 5.99915C27.7182 5.99915 33.9983 12.2792 33.9983 19.9988C33.9983 27.7183 27.7182 33.9984 19.9987 33.9984ZM19.9987 7.99854C13.3816 7.99854 7.99843 13.3818 7.99843 19.9988C7.99843 26.6158 13.3816 31.999 19.9987 31.999C26.6157 31.999 31.9989 26.6158 31.9989 19.9988C31.9989 13.3818 26.6157 7.99854 19.9987 7.99854Z"
        fill="currentColor"
      />
      <path
        d="M13.999 26.999C13.7431 26.999 13.4873 26.9015 13.2923 26.7065C12.9013 26.3155 12.9013 25.6832 13.2923 25.2922L25.2925 13.2919C25.6835 12.9019 26.3159 12.9019 26.7069 13.2919C27.0979 13.6829 27.0979 14.3153 26.7069 14.7063L14.7066 26.7065C14.5116 26.9015 14.2558 26.999 13.9999 26.999H13.999Z"
        fill="currentColor"
      />
      <path
        d="M17.8775 16.9972C17.109 16.9972 16.3406 16.7047 15.7565 16.1196C14.5864 14.9496 14.5864 13.0467 15.7565 11.8766C16.9266 10.7075 18.8294 10.7075 19.9986 11.8766C21.1686 13.0467 21.1686 14.9496 19.9986 16.1196C19.4135 16.7047 18.646 16.9972 17.8775 16.9972ZM17.8775 12.9994C17.6217 12.9994 17.3649 13.0969 17.1699 13.2919C16.9816 13.4811 16.8773 13.7322 16.8773 13.9986C16.8773 14.2651 16.9816 14.517 17.1699 14.7053C17.5599 15.0953 18.1942 15.0953 18.5842 14.7053C18.7734 14.5161 18.8767 14.2651 18.8767 13.9986C18.8767 13.7322 18.7725 13.4802 18.5842 13.2919C18.3959 13.1037 18.1334 12.9994 17.8775 12.9994Z"
        fill="currentColor"
      />
      <path
        d="M21.9986 28.9975C21.2301 28.9975 20.4626 28.7049 19.8776 28.1199C18.7075 26.9498 18.7075 25.0469 19.8776 23.8768C21.0477 22.7067 22.9505 22.7077 24.1206 23.8768C25.2898 25.0469 25.2898 26.9498 24.1206 28.1199C23.5356 28.7049 22.7681 28.9975 21.9996 28.9975H21.9986ZM21.9986 24.9996C21.7428 24.9996 21.486 25.0971 21.2919 25.2922C20.9019 25.6822 20.9019 26.3165 21.2919 26.7065C21.682 27.0965 22.3162 27.0965 22.7063 26.7065C23.0963 26.3165 23.0963 25.6822 22.7063 25.2922C22.5113 25.0971 22.2554 24.9996 21.9996 24.9996H21.9986Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default LocationIcon;
