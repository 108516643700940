import { PropsSVG } from "./types";

function ChatIcon({ height = 20, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M33 22.425C33 20.7448 33 19.9048 32.673 19.263C32.3854 18.6985 31.9265 18.2396 31.362 17.952C30.7202 17.625 29.8802 17.625 28.2 17.625H19.8C18.1198 17.625 17.2798 17.625 16.638 17.952C16.0735 18.2396 15.6146 18.6985 15.327 19.263C15 19.9048 15 20.7448 15 22.425V26.1964C15 27.8766 15 28.7167 15.327 29.3584C15.6146 29.9229 16.0735 30.3818 16.638 30.6695C17.2798 30.9964 18.1198 30.9964 19.8 30.9964H22.4954C23.0543 30.9964 23.3338 30.9964 23.6034 31.0461C23.8426 31.0902 24.0757 31.1633 24.2973 31.2636C24.547 31.3767 24.7765 31.5363 25.2354 31.8553L28.5 34.125V30.9964C29.8978 30.9964 30.5967 30.9964 31.1481 30.7681C31.8831 30.4636 32.4672 29.8796 32.7716 29.1445C33 28.5932 33 27.8943 33 26.4964V22.425Z"
        fill="white"
      />
      <path
        d="M15 23.625L10.3871 28.2955C9.74368 28.947 9.42196 29.2728 9.14543 29.2957C8.90552 29.3157 8.67062 29.2192 8.51394 29.0365C8.33333 28.8258 8.33333 28.368 8.33333 27.4523V25.1124C8.33333 24.2909 7.66061 23.6965 6.84781 23.5775V23.5775C4.88062 23.2893 3.33566 21.7444 3.04751 19.7772C3 19.4528 3 19.0658 3 18.2917V11.325C3 8.80476 3 7.54465 3.49047 6.58204C3.9219 5.73531 4.61031 5.0469 5.45704 4.61547C6.41965 4.125 7.67976 4.125 10.2 4.125H21.3C23.8202 4.125 25.0804 4.125 26.043 4.61547C26.8897 5.0469 27.5781 5.73531 28.0095 6.58204C28.5 7.54465 28.5 8.80476 28.5 11.325V17.625M28.5 34.125L25.2354 31.8553C24.7765 31.5363 24.547 31.3767 24.2973 31.2636C24.0757 31.1633 23.8426 31.0902 23.6034 31.0461C23.3338 30.9964 23.0543 30.9964 22.4954 30.9964H19.8C18.1198 30.9964 17.2798 30.9964 16.638 30.6695C16.0735 30.3818 15.6146 29.9229 15.327 29.3584C15 28.7167 15 27.8766 15 26.1964V22.425C15 20.7448 15 19.9048 15.327 19.263C15.6146 18.6985 16.0735 18.2396 16.638 17.952C17.2798 17.625 18.1198 17.625 19.8 17.625H28.2C29.8802 17.625 30.7202 17.625 31.362 17.952C31.9265 18.2396 32.3854 18.6985 32.673 19.263C33 19.9048 33 20.7448 33 22.425V26.4964C33 27.8943 33 28.5932 32.7716 29.1445C32.4672 29.8796 31.8831 30.4636 31.1481 30.7681C30.5967 30.9964 29.8978 30.9964 28.5 30.9964V34.125Z"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <circle cx="19.125" cy="24.75" r="1.125" fill="#172C37" />
      <circle cx="23.625" cy="24.75" r="1.125" fill="#172C37" />
      <circle cx="28.125" cy="24.75" r="1.125" fill="#172C37" />
    </svg>
  );
}

export default ChatIcon;
