import { PropsSVG } from "./types";

function ScrollDownIcon({ height = 20, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 20 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-color"
    >
      <g clipPath="url(#clip0_1070_1807)">
        <path
          d="M10 4L10 36"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M4.16634 30L9.99967 35.8333L15.833 30"
          stroke="currentColor"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_1070_1807">
          <rect
            width="40"
            height="20"
            fill="white"
            transform="matrix(4.37114e-08 -1 -1 -4.37114e-08 20 40)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

export default ScrollDownIcon;
