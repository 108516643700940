import { PropsSVG } from "./types";

function PromoCodeIcon({ ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
      fill="none"
      className="text-highlightText"
    >
      <path
        d="M19.3842 48C19.1284 48 18.8725 47.9025 18.6765 47.7074L0.291553 29.3225C-0.0984865 28.9315 -0.0984865 28.2991 0.291553 27.9081L21.6752 6.52444C24.3196 3.88009 27.8347 2.42419 31.5749 2.42419C35.315 2.42419 38.8302 3.88009 41.4745 6.52444C44.1189 9.16879 45.5748 12.6849 45.5748 16.4241C45.5748 20.1633 44.1189 23.6794 41.4745 26.3238L20.0909 47.7074C19.8959 47.9025 19.64 48 19.3842 48ZM2.4136 28.6148L19.3842 45.5854L40.0602 24.9094C42.327 22.6425 43.5754 19.6294 43.5754 16.4241C43.5754 13.2188 42.327 10.2057 40.0602 7.93882C37.7933 5.67195 34.7802 4.42363 31.5749 4.42363C28.3696 4.42363 25.3564 5.67195 23.0896 7.93882L2.4136 28.6148Z"
        fill="currentColor"
      />
      <path
        d="M34.2384 23.246H17.2678C16.7155 23.246 16.2676 22.798 16.2676 22.2458C16.2676 21.6936 16.7155 21.2456 17.2678 21.2456H34.2384C34.7906 21.2456 35.2386 21.6936 35.2386 22.2458C35.2386 22.798 34.7906 23.246 34.2384 23.246Z"
        fill="currentColor7"
      />
      <path
        d="M28.4957 19.5039C26.8419 19.5039 25.4961 18.1581 25.4961 16.5043C25.4961 14.8505 26.8419 13.5046 28.4957 13.5046C30.1495 13.5046 31.4954 14.8505 31.4954 16.5043C31.4954 18.1581 30.1495 19.5039 28.4957 19.5039ZM28.4957 15.5041C27.9445 15.5041 27.4955 15.953 27.4955 16.5043C27.4955 17.0555 27.9445 17.5045 28.4957 17.5045C29.047 17.5045 29.4959 17.0555 29.4959 16.5043C29.4959 15.953 29.047 15.5041 28.4957 15.5041Z"
        fill="currentColor"
      />
      <path
        d="M22.9244 30.903C21.2706 30.903 19.9248 29.5571 19.9248 27.9033C19.9248 26.2495 21.2706 24.9037 22.9244 24.9037C24.5783 24.9037 25.9241 26.2495 25.9241 27.9033C25.9241 29.5571 24.5783 30.903 22.9244 30.903ZM22.9244 26.9031C22.3732 26.9031 21.9242 27.3521 21.9242 27.9033C21.9242 28.4546 22.3732 28.9035 22.9244 28.9035C23.4757 28.9035 23.9246 28.4546 23.9246 27.9033C23.9246 27.3521 23.4757 26.9031 22.9244 26.9031Z"
        fill="currentColor"
      />
      <path
        d="M22.2123 45.1722C21.9565 45.1722 21.7006 45.0747 21.5047 44.8797L3.12065 26.4938C2.73062 26.1028 2.73062 25.4704 3.12065 25.0794C3.51166 24.6894 4.14403 24.6894 4.53503 25.0794L22.92 43.4644C23.311 43.8554 23.311 44.4877 22.92 44.8787C22.725 45.0738 22.4691 45.1713 22.2133 45.1713L22.2123 45.1722Z"
        fill="currentColor"
      />
      <path
        d="M25.0453 42.3386C24.7895 42.3386 24.5337 42.2411 24.3386 42.0461L5.95366 23.6602C5.56362 23.2691 5.56362 22.6368 5.95366 22.2458C6.34467 21.8557 6.97703 21.8557 7.36804 22.2458L25.753 40.6307C26.144 41.0218 26.144 41.6541 25.753 42.0451C25.558 42.2401 25.3022 42.3377 25.0463 42.3377L25.0453 42.3386Z"
        fill="currentColor"
      />
      <path
        d="M36.5 12.4996C36.2441 12.4996 35.9883 12.4021 35.7933 12.2071C35.4022 11.8161 35.4022 11.1837 35.7933 10.7927L46.2934 0.29253C46.6844 -0.09751 47.3168 -0.09751 47.7078 0.29253C48.0988 0.683535 48.0988 1.3159 47.7078 1.70691L37.2076 12.2071C37.0126 12.4021 36.7568 12.4996 36.5009 12.4996H36.5Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default PromoCodeIcon;
