import { PropsSVG } from "./types";

function WhatsAppIcon({ height = 40, width = 40, ...props }: PropsSVG) {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="40"
        height="40"
        rx="20"
        fill="url(#paint0_linear_1308_5897)"
      />
      <path
        d="M7.86816 31.6357L9.58305 25.4084C8.52283 23.5806 7.96665 21.5106 7.97245 19.3945C7.97245 12.7635 13.3952 7.37231 20.052 7.37231C23.2848 7.37231 26.3206 8.62354 28.5975 10.8953C30.8801 13.1672 32.1373 16.1886 32.1315 19.4002C32.1315 26.0311 26.7088 31.4224 20.0462 31.4224H20.0404C18.0185 31.4224 16.0313 30.915 14.2642 29.9578L7.86816 31.6357ZM14.5713 27.784L14.9363 28.0031C16.4774 28.9141 18.2444 29.3927 20.0462 29.3985H20.052C25.5848 29.3985 30.0922 24.9183 30.0922 19.406C30.0922 16.7363 29.0494 14.2281 27.1549 12.3369C25.2604 10.4456 22.7344 9.40772 20.052 9.40772C14.5192 9.40195 10.0118 13.8821 10.0118 19.3945C10.0118 21.2799 10.539 23.1193 11.5471 24.7107L11.7846 25.0913L10.7707 28.7758L14.5713 27.784Z"
        fill="white"
      />
      <path
        d="M8.29102 31.2148L9.94797 25.2008C8.92251 23.4422 8.38371 21.4414 8.38371 19.4002C8.38951 12.9999 13.6211 7.79321 20.0519 7.79321C23.1746 7.79321 26.1004 9.00408 28.3019 11.1952C30.5035 13.3862 31.7143 16.3039 31.7143 19.406C31.7143 25.8062 26.477 31.013 20.0519 31.013H20.0461C18.0937 31.013 16.176 30.5228 14.4727 29.6003L8.29102 31.2148Z"
        fill="url(#paint1_linear_1308_5897)"
      />
      <path
        d="M7.86816 31.6357L9.58305 25.4084C8.52283 23.5806 7.96665 21.5106 7.97245 19.3945C7.97245 12.7635 13.3952 7.37231 20.052 7.37231C23.2848 7.37231 26.3206 8.62354 28.5975 10.8953C30.8801 13.1672 32.1373 16.1886 32.1315 19.4002C32.1315 26.0311 26.7088 31.4224 20.0462 31.4224H20.0404C18.0185 31.4224 16.0313 30.915 14.2642 29.9578L7.86816 31.6357ZM14.5713 27.784L14.9363 28.0031C16.4774 28.9141 18.2444 29.3927 20.0462 29.3985H20.052C25.5848 29.3985 30.0922 24.9183 30.0922 19.406C30.0922 16.7363 29.0494 14.2281 27.1549 12.3369C25.2604 10.4456 22.7344 9.40772 20.052 9.40772C14.5192 9.40195 10.0118 13.8821 10.0118 19.3945C10.0118 21.2799 10.539 23.1193 11.5471 24.7107L11.7846 25.0913L10.7707 28.7758L14.5713 27.784Z"
        fill="url(#paint2_linear_1308_5897)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0334 14.3665C16.8075 13.8649 16.5699 13.8533 16.3556 13.8476C16.1818 13.8418 15.979 13.8418 15.7762 13.8418C15.5735 13.8418 15.249 13.9168 14.9709 14.2166C14.6928 14.5164 13.9165 15.2429 13.9165 16.7248C13.9165 18.2009 14.9999 19.6309 15.1505 19.8327C15.3012 20.0345 17.242 23.1654 20.3068 24.3705C22.8559 25.3738 23.3774 25.172 23.9278 25.1201C24.4781 25.0682 25.7122 24.3936 25.9671 23.6902C26.2162 22.9867 26.2162 22.387 26.1409 22.2602C26.0656 22.1333 25.8628 22.0584 25.5615 21.9085C25.2603 21.7585 23.7771 21.032 23.499 20.9282C23.2209 20.8302 23.0182 20.7783 22.8212 21.0781C22.6184 21.378 22.0391 22.0526 21.8652 22.2544C21.6914 22.4562 21.5118 22.4793 21.2106 22.3294C20.9093 22.1795 19.936 21.8623 18.7831 20.836C17.8851 20.0403 17.2768 19.0543 17.103 18.7544C16.9291 18.4546 17.0856 18.2932 17.2362 18.1432C17.3695 18.0106 17.5375 17.7915 17.6881 17.6185C17.8387 17.4456 17.8909 17.3187 17.9894 17.1169C18.0879 16.9151 18.0415 16.7421 17.9662 16.5922C17.8909 16.448 17.2999 14.9604 17.0334 14.3665Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_1308_5897"
          x1="19.999"
          y1="39.998"
          x2="19.999"
          y2="-0.0013995"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038" />
          <stop offset="1" stopColor="#60D66A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_1308_5897"
          x1="20.0021"
          y1="31.2136"
          x2="20.0021"
          y2="7.79239"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038" />
          <stop offset="1" stopColor="#60D66A" />
        </linearGradient>
        <linearGradient
          id="paint2_linear_1308_5897"
          x1="20.0022"
          y1="31.6334"
          x2="20.0022"
          y2="7.37231"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default WhatsAppIcon;
