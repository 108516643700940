import { PropsSVG } from "./types";

function Heart({ height = 18, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.43816 2.97516C6.88126 1.38287 4.31928 1.38287 2.76238 2.97516C1.25418 4.51764 1.25417 6.98234 2.76238 8.52481L9.86155 15.7853C9.93765 15.8632 10.0629 15.8632 10.139 15.7853L17.2382 8.52482C18.7464 6.98234 18.7464 4.51764 17.2382 2.97516C15.6813 1.38287 13.1193 1.38287 11.5624 2.97516L10.5365 4.02433C10.3954 4.16864 10.2021 4.24999 10.0003 4.24999C9.79844 4.24999 9.60512 4.16864 9.46402 4.02433L8.43816 2.97516ZM1.68987 1.92648C3.83516 -0.267568 7.36538 -0.267568 9.51067 1.92648L10.0003 2.42721L10.4899 1.92649C12.6352 -0.267566 16.1654 -0.267569 18.3107 1.92648C20.3889 4.0519 20.3889 7.44807 18.3107 9.57349L11.2115 16.834C10.547 17.5136 9.45353 17.5136 8.78904 16.834L1.68987 9.57349C-0.388323 7.44807 -0.388319 4.0519 1.68987 1.92648Z"
        fill={props.fill}
        stroke={props.stroke}
      />
    </svg>
  );
}

export default Heart;
