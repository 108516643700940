import { PropsSVG } from "./types";

function ChevronsRightIcon({ height = 20, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8333 14.6667L14.9999 10.5L10.8333 6.33337"
        stroke="#172C37"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 14.6667L9.16667 10.5L5 6.33337"
        stroke="#172C37"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default ChevronsRightIcon;
