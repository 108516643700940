import { PropsSVG } from "./types";

function MobileFilterIcon({ height = 13, width = 12, ...props }: PropsSVG) {
  return (
    <svg
      {...props}
      width={width}
      height={height}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6 2.5C5.72386 2.5 5.5 2.72386 5.5 3C5.5 3.27614 5.72386 3.5 6 3.5V2.5ZM10 3.5C10.2761 3.5 10.5 3.27614 10.5 3C10.5 2.72386 10.2761 2.5 10 2.5V3.5ZM6 9.5C5.72386 9.5 5.5 9.72386 5.5 10C5.5 10.2761 5.72386 10.5 6 10.5V9.5ZM10 10.5C10.2761 10.5 10.5 10.2761 10.5 10C10.5 9.72386 10.2761 9.5 10 9.5V10.5ZM6 7C6.27614 7 6.5 6.77614 6.5 6.5C6.5 6.22386 6.27614 6 6 6V7ZM2 6C1.72386 6 1.5 6.22386 1.5 6.5C1.5 6.77614 1.72386 7 2 7L2 6ZM6 3.5L10 3.5V2.5L6 2.5V3.5ZM6 10.5H10V9.5H6V10.5ZM6 6L2 6L2 7L6 7V6ZM1.5 3C1.5 3.82843 2.17157 4.5 3 4.5V3.5C2.72386 3.5 2.5 3.27614 2.5 3H1.5ZM3 4.5C3.82843 4.5 4.5 3.82843 4.5 3H3.5C3.5 3.27614 3.27614 3.5 3 3.5V4.5ZM4.5 3C4.5 2.17157 3.82843 1.5 3 1.5V2.5C3.27614 2.5 3.5 2.72386 3.5 3H4.5ZM3 1.5C2.17157 1.5 1.5 2.17157 1.5 3H2.5C2.5 2.72386 2.72386 2.5 3 2.5V1.5ZM1.5 10C1.5 10.8284 2.17157 11.5 3 11.5V10.5C2.72386 10.5 2.5 10.2761 2.5 10H1.5ZM3 11.5C3.82843 11.5 4.5 10.8284 4.5 10H3.5C3.5 10.2761 3.27614 10.5 3 10.5V11.5ZM4.5 10C4.5 9.17157 3.82843 8.5 3 8.5V9.5C3.27614 9.5 3.5 9.72386 3.5 10H4.5ZM3 8.5C2.17157 8.5 1.5 9.17157 1.5 10H2.5C2.5 9.72386 2.72386 9.5 3 9.5V8.5ZM10.5 6.5C10.5 5.67157 9.82843 5 9 5V6C9.27614 6 9.5 6.22386 9.5 6.5H10.5ZM9 5C8.17157 5 7.5 5.67157 7.5 6.5H8.5C8.5 6.22386 8.72386 6 9 6V5ZM7.5 6.5C7.5 7.32843 8.17157 8 9 8V7C8.72386 7 8.5 6.77614 8.5 6.5H7.5ZM9 8C9.82843 8 10.5 7.32843 10.5 6.5H9.5C9.5 6.77614 9.27614 7 9 7V8Z"
        fill="currentColor"
      />
    </svg>
  );
}

export default MobileFilterIcon;
