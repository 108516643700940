import { PropsSVG } from "./types";

function AlertSuccessIcon({ width = 24, height = 24, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="12" fill="#47CFD6" />
      <path
        d="M17.3332 8L9.99984 15.3333L6.6665 12"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default AlertSuccessIcon;
