import { PropsSVG } from "./types";

function PhoneIcon({ height = 18, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M10.8249 2.79175C11.5423 2.79175 11.8598 2.79238 12.1216 2.81582C15.0327 3.07646 17.3398 5.38355 17.6004 8.29465C17.6239 8.55648 17.6245 8.87398 17.6245 9.59137V10.4163C17.6245 10.7615 17.9043 11.0413 18.2495 11.0413C18.5947 11.0413 18.8745 10.7615 18.8745 10.4163V9.54934C18.8745 8.88526 18.8745 8.50786 18.8455 8.18318C18.5309 4.66979 15.7465 1.88536 12.2331 1.5708C11.9084 1.54173 11.531 1.54174 10.8668 1.54175H9.99993C9.65475 1.54175 9.37493 1.82157 9.37493 2.16675C9.37493 2.51193 9.65475 2.79175 9.99993 2.79175L10.8249 2.79175Z"
        fill="#172C37"
      />
      <path
        d="M5.92636 3.8545C5.04962 3.04889 3.702 3.04889 2.82525 3.8545C2.78935 3.88749 2.75102 3.92582 2.70125 3.9756L1.95333 4.72353C1.20074 5.47612 0.884805 6.56105 1.11569 7.60004C2.42567 13.4949 7.02944 18.0987 12.9243 19.4087C13.9633 19.6396 15.0482 19.3236 15.8008 18.571L16.5487 17.8232C16.5985 17.7734 16.6369 17.735 16.6699 17.6991C17.4755 16.8224 17.4755 15.4747 16.6699 14.598C16.6369 14.5621 16.5985 14.5237 16.5487 14.4739L15.3243 13.2495C14.4742 12.3995 13.1904 12.1563 12.0884 12.6367C11.4575 12.9117 10.7225 12.7725 10.2358 12.2859L8.2385 10.2885C7.75183 9.80186 7.61264 9.0669 7.88766 8.43598C8.36804 7.33393 8.12492 6.05016 7.27483 5.20007L6.05039 3.97563C6.00061 3.92584 5.96227 3.88749 5.92636 3.8545Z"
        fill="#172C37"
      />
      <path
        d="M10.5891 4.48803C10.2439 4.48803 9.96409 4.76785 9.96409 5.11303C9.96409 5.45821 10.2439 5.73803 10.5891 5.73803H11.0605C11.1332 5.73803 11.163 5.73804 11.1872 5.73839C13.1035 5.76584 14.6503 7.31266 14.6778 9.22898C14.6781 9.25321 14.6781 9.28297 14.6781 9.35567V9.82707C14.6781 10.1722 14.958 10.4521 15.3031 10.4521C15.6483 10.4521 15.9281 10.1722 15.9281 9.82707V9.34962C15.9281 9.28486 15.9281 9.24545 15.9276 9.21107C15.8905 6.61842 13.7977 4.52565 11.2051 4.48852C11.1707 4.48803 11.1313 4.48803 11.0665 4.48803H10.5891Z"
        fill="#172C37"
      />
    </svg>
  );
}

export default PhoneIcon;
