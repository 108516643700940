import { PropsSVG } from "./types";

function FloorPlanIcon({ height = 20, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={18}
      height={17}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="text-tableText"
    >
      <path
        d="M7.54655 12.2571H2.62231V2.12488H15.3327V14.8746H11.1661V12.2571H9.10379"
        stroke="currentColor"
        strokeWidth="1.0625"
      />
      <path
        d="M5.14771 7.10657H10.7033"
        stroke="currentColor"
        strokeWidth="1.0625"
      />
      <path
        d="M2.66455 7.14893H3.75883"
        stroke="currentColor"
        strokeWidth="1.0625"
      />
      <path
        d="M6.53613 7.14883L6.53613 8.33092"
        stroke="currentColor"
        strokeWidth="1.0625"
      />
      <path
        d="M6.53613 9.76651L6.53613 12.2151"
        stroke="currentColor"
        strokeWidth="1.0625"
      />
      <path
        d="M10.0715 9.76651L10.0715 12.2151"
        stroke="currentColor"
        strokeWidth="1.0625"
      />
      <path
        d="M10.0715 7.14883L10.0715 8.33092"
        stroke="currentColor"
        strokeWidth="1.0625"
      />
      <path
        d="M7.54663 2.16693L7.54663 7.1486"
        stroke="currentColor"
        strokeWidth="1.0625"
      />
      <path
        d="M12.092 7.14883L15.3749 7.14883"
        stroke="currentColor"
        strokeWidth="1.0625"
      />
    </svg>
  );
}

export default FloorPlanIcon;
