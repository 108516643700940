import { PropsSVG } from "./types";

function Whatsapp({ height = 20, width = 20, ...props }: PropsSVG) {
  return (
    <svg
      width={width}
      height={height}
      {...props}
      viewBox="0 0 34 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.015625 34.2902L2.41647 25.5719C0.932164 23.013 0.153511 20.115 0.161622 17.1524C0.161622 7.86912 7.75348 0.321411 17.073 0.321411C21.5989 0.321411 25.849 2.07313 29.0366 5.25366C32.2324 8.43419 33.9924 12.6641 33.9843 17.1605C33.9843 26.4438 26.3925 33.9915 17.0649 33.9915H17.0568C14.226 33.9915 11.444 33.2811 8.97013 31.9411L0.015625 34.2902ZM9.40001 28.8978L9.911 29.2045C12.0685 30.48 14.5424 31.15 17.0649 31.1581H17.073C24.8189 31.1581 31.1293 24.8858 31.1293 17.1686C31.1293 13.431 29.6693 9.91952 27.017 7.27177C24.3647 4.62401 20.8284 3.17098 17.073 3.17098C9.32701 3.1629 3.01668 9.43517 3.01668 17.1524C3.01668 19.7921 3.75478 22.3672 5.16609 24.5952L5.49864 25.128L4.07922 30.2862L9.40001 28.8978Z"
        fill="white"
      />
      <path
        d="M0.607422 33.7008L2.92716 25.2813C1.49152 22.8192 0.737197 20.0181 0.737197 17.1604C0.745308 8.20004 8.06951 0.910645 17.0727 0.910645C21.4445 0.910645 25.5405 2.60585 28.6227 5.67337C31.7049 8.74089 33.4 12.8255 33.4 17.1685C33.4 26.1289 26.0677 33.4183 17.0727 33.4183H17.0646C14.3312 33.4183 11.6464 32.7321 9.26182 31.4405L0.607422 33.7008Z"
        fill="url(#paint0_linear_9344_8963)"
      />
      <path
        d="M0.015625 34.2902L2.41647 25.5719C0.932164 23.013 0.153511 20.115 0.161622 17.1524C0.161622 7.86912 7.75348 0.321411 17.073 0.321411C21.5989 0.321411 25.849 2.07313 29.0366 5.25366C32.2324 8.43419 33.9924 12.6641 33.9843 17.1605C33.9843 26.4438 26.3925 33.9915 17.0649 33.9915H17.0568C14.226 33.9915 11.444 33.2811 8.97013 31.9411L0.015625 34.2902ZM9.40001 28.8978L9.911 29.2045C12.0685 30.48 14.5424 31.15 17.0649 31.1581H17.073C24.8189 31.1581 31.1293 24.8858 31.1293 17.1686C31.1293 13.431 29.6693 9.91952 27.017 7.27177C24.3647 4.62401 20.8284 3.17098 17.073 3.17098C9.32701 3.1629 3.01668 9.43517 3.01668 17.1524C3.01668 19.7921 3.75478 22.3672 5.16609 24.5952L5.49864 25.128L4.07922 30.2862L9.40001 28.8978Z"
        fill="url(#paint1_linear_9344_8963)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.8471 10.1133C12.5308 9.41095 12.1982 9.39481 11.8981 9.38674C11.6548 9.37866 11.3709 9.37866 11.087 9.37866C10.8031 9.37866 10.3489 9.4836 9.95959 9.90337C9.57027 10.3231 8.4834 11.3403 8.4834 13.4149C8.4834 15.4814 10.0001 17.4834 10.211 17.7659C10.4219 18.0484 13.1391 22.4318 17.4298 24.1189C20.9986 25.5235 21.7286 25.241 22.4991 25.1683C23.2697 25.0957 24.9973 24.1512 25.3542 23.1664C25.703 22.1815 25.703 21.342 25.5975 21.1644C25.4921 20.9868 25.2082 20.8819 24.7864 20.672C24.3647 20.4621 22.2883 19.445 21.8989 19.2997C21.5096 19.1624 21.2257 19.0898 20.95 19.5095C20.6661 19.9293 19.855 20.8738 19.6116 21.1563C19.3683 21.4389 19.1169 21.4711 18.6951 21.2613C18.2733 21.0514 16.9107 20.6074 15.2966 19.1705C14.0394 18.0565 13.1878 16.6761 12.9444 16.2564C12.7011 15.8366 12.9201 15.6106 13.131 15.4007C13.3175 15.215 13.5528 14.9083 13.7636 14.6661C13.9745 14.4239 14.0475 14.2463 14.1854 13.9638C14.3233 13.6813 14.2584 13.4391 14.153 13.2292C14.0475 13.0274 13.2202 10.9447 12.8471 10.1133Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_9344_8963"
          x1="17.0029"
          y1="33.6992"
          x2="17.0029"
          y2="0.909497"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#20B038" />
          <stop offset="1" stopColor="#60D66A" />
        </linearGradient>
        <linearGradient
          id="paint1_linear_9344_8963"
          x1="17.0033"
          y1="34.2869"
          x2="17.0033"
          y2="0.321411"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#F9F9F9" />
          <stop offset="1" stopColor="white" />
        </linearGradient>
      </defs>
    </svg>
  );
}

export default Whatsapp;
