/**
 * `ForwardedIcon` is a React component that renders an icon.
 * It uses the `forwardRef` function to pass a ref to the `div` element that wraps the icon.
 *
 * @component
 * @example
 * <ForwardedIcon TypeIcon={SomeIcon} size="small" fill="#000" stroke="#fff" className="custom-class" onClick={handleClick} />
 */
import { forwardRef } from "react";

import { IconProps } from "./types";

import { cn } from "~/utilities/cn";

/**
 * `ForwardedIcon` is a functional component that accepts `IconProps` and a `ref`.
 * It destructures the props to get the `TypeIcon`, `size`, `fill`, `stroke`, `className`, and `onClick`.
 * It returns a `div` that wraps the `TypeIcon` component.
 * The `div` has a role of "button", a tabIndex of 0, and a click handler.
 * The `className` is generated using the `cn` utility function.
 *
 * @param {Object} props - The props that define the icon.
 * @param {React.Ref} ref - The ref passed to the `div` element.
 * @returns {React.Element} A `div` element that wraps the `TypeIcon` component.
 */
const ForwardedIcon = forwardRef<HTMLDivElement, IconProps>((props, ref) => {
  const {
    TypeIcon,
    size,
    fill,
    width,
    height,
    stroke,
    className = "",
    onClick,
  } = props;

  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      ref={ref}
      role="button"
      tabIndex={0}
      className={cn(
        "cursor-inherit flex items-center justify-center",
        size === "small" && ["h-6 w-6"],
        size === "big" && ["h-10 w-10"],
        className
      )}
      onClick={onClick}
    >
      <TypeIcon width={width} height={height} fill={fill} stroke={stroke} />
    </div>
  );
});

// Set the display name of the component for debugging purposes.
ForwardedIcon.displayName = "Icon";

// Export the `ForwardedIcon` component as the default export.
export default ForwardedIcon;
